import React from 'react'
import { useLocation } from '@reach/router'
import { useLocalStorage } from '../hooks/localstorage.js'
import qs from 'query-string'

const useQueryLanguage = props => {
    const [currentLang, setCurrentLang] = useLocalStorage('language', 'en')
    const loc = useLocation()
    React.useEffect(() => {
        const { lang } = qs.parse(loc.search)
        const supported = ['en', 'jp']
        if (lang) {
            if (currentLang !== lang && supported.includes(lang)) {
                setCurrentLang(lang)
            }
        }
    }, [currentLang, setCurrentLang])
    return null
}

export { useQueryLanguage }
